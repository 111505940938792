import React, { useContext } from "react";
import DotNetImg from "../../assets/images/tech/net@2x.png";
import PythonImg from "../../assets/images/tech/python@2x.png";
import NodeJSImg from "../../assets/images/tech/node.js@2x.png";
import ReactImg from "../../assets/images/tech/react-web@2x.png";
import AngularImg from "../../assets/images/tech/angular@2x.png";
import ReactNativeImg from "../../assets/images/tech/react-native@2x.png";
import AndroidImg from "../../assets/images/tech/android@2x.png";
import IOSImg from "../../assets/images/tech/ios@2x.png";
import AWSImg from "../../assets/images/tech/amazone@2x.png";
import AzureImg from "../../assets/images/tech/azure@2x.png";
import { ConfigContext } from "../../App";

//Temp data
const dataTechnologiesPanel = {
  order: 3,
  visible: true,
  headlineText: "Trusted By Our Technologies",
  technologyImages: [
    DotNetImg,
    PythonImg,
    NodeJSImg,
    ReactImg,
    AngularImg,
    AndroidImg,
    ReactNativeImg,
    IOSImg,
    AWSImg,
    AzureImg,
  ],
};

export default function TechnologiesPanel() {
  const configuration: any = useContext(ConfigContext);

  return (
    <section className="py-14 bg-white relative before:block before:absolute before:w-full before:top-0 before:left-0 before:h-14 before:bg-gradient-to-b before:from-[#f8f8f8] before:to-[#ffffff]">
      <div className="container">
        <h3 className="text-base font-bold font-nunito uppercase pb-6 md:pb-8 text-center">
          {configuration.technologiesPanel.headlineText}
        </h3>
        <ul className="grid grid-cols-2 md:grid-cols-5 py-4 gap-4 md:gap-10 mb-0 md:mb-6">
          {(
            configuration.technologiesPanel.technologyImages ??
            dataTechnologiesPanel.technologyImages
          ).map((item, i) => (
            <li
              className="flex justify-center items-start gap-4"
              key={`tech-item-${i}`}
            >
              <img src={item} alt="" className="w-32" />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
