/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import TechnologiesPanel from "./TechnologiesPanel";
import WorkingWithPanel from "./WorkingWithPanel";
import VideoPanel from "./VideoPanel";
import GreatPlacePanel from "./GreatPlacePanel";
import StatisticsPanel from "./StatisticsPanel";
import VietnamPanel from "./VietnamPanel";
import BackTopIcon from "../assets/images/footer/back-top@2x.png";
import RolePanel from "./RolePanel";
import { ConfigContext } from "../App";
import { sortBy } from "lodash";
import Modal from "../components/Modal";
import { isValidEmail } from "../utils/utils";
import { addSubscriberAPI } from "../utils/api";
import { useLocation } from "react-router-dom";

export default function Page() {
  const configuration: any = useContext(ConfigContext);
  const configSorted = sortBy(Object(configuration), "order");

  const search = useLocation().search;
  const campaignId = new URLSearchParams(search).get("campaignId") ?? "root";

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleChange = (event: any) => {
    if (isValidEmail(event.target.value)) {
      setError(null);
    }
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      setError("Email is invalid");
      return;
    } else {
      setError(null);
    }
    setLoading(true);
    const res = await addSubscriberAPI({ email, campaignId });

    if (res === email) {
      setShowModal(true);
      setIsError(false);
    } else {
      setShowModal(true);
      setIsError(true);
    }
    setLoading(false);
    setEmail("");
  };

  const closeModal = () => {
    setShowModal(false);
    setIsError(false);
  };

  const sections = [
    {
      id: "heroPanel",
      content: (
        <Header
          email={email}
          loading={loading}
          isError={isError}
          error={error}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          closeModal={closeModal}
          showModal={showModal}
        />
      ),
    },
    {
      id: "statisticsPanel",
      content: <StatisticsPanel />,
    },
    {
      id: "technologiesPanel",
      content: <TechnologiesPanel />,
    },
    {
      id: "rolePanel",
      content: <RolePanel />,
    },
    {
      id: "workingWithPanel",
      content: <WorkingWithPanel />,
    },
    {
      id: "videoPanel",
      content: <VideoPanel />,
    },
    {
      id: "greatPlacePanel",
      content: <GreatPlacePanel />,
    },
    {
      id: "vietnamPanel",
      content: <VietnamPanel />,
    },
  ];

  return (
    <div>
      {configSorted?.map((item: any) => {
        if (item.visible) {
          return sections.map(
            (section: any) =>
              section.id === item.id && (
                <div key={section.id}>{section.content}</div>
              )
          );
        }
      })}
      <Footer />

      <a href="#" className="scroll-top">
        <img src={BackTopIcon} alt="Back to Top" />
      </a>
      <Modal
        showModal={showModal}
        closeModal={closeModal}
        msg={
          isError
            ? configuration.submitEmailPanel.errorMessage
            : configuration.submitEmailPanel.successMessage
        }
        ctaCloseModal={configuration.submitEmailPanel.ctaCloseModal}
        ctaCloseModalLink={configuration.submitEmailPanel.ctaCloseModalLink}
        isError={isError}
      />
    </div>
  );
}
