import { useContext, useEffect, useState } from "react";
import LogoImg from "../../assets/images/logo@2x.png";
import LogoWhiteImg from "../../assets/images/logo-white@2x.png";
import HeroImg from "../../assets/images/team-2x-min.png";
import HeroPanel from "../HeroPanel";
import clsx from "clsx";
import { ConfigContext } from "../../App";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

export default function Header({
  email,
  loading,
  isError,
  error,
  showModal,
  handleChange,
  handleSubmit,
  closeModal,
}: {
  email: string;
  loading: boolean;
  isError: boolean;
  showModal: boolean;
  error: string | null;
  handleChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  closeModal: () => void;
}) {
  const [sticky, setSticky] = useState<boolean>(false);
  const [stickyCTA, setStickyCTA] = useState<boolean>(false);
  const configuration: any = useContext(ConfigContext);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 40;
    const stickyCTAClass = scrollTop >= 560;
    setSticky(stickyClass);
    setStickyCTA(stickyCTAClass);
  };

  return (
    <section
      className="header_area relative z-30 after:block after:absolute after:bottom-0 after:left-0 after:w-full after:h-8 after:bg-gradient-to-b after:from-transparent after:to-[#f8f8f8] bg-left md:bg-cover xl:bg-right xl:bg-contain bg-no-repeat bg-none"
      style={{
        backgroundImage: `url(${configuration.heroPanel.heroImage ?? HeroImg})`,
      }}
    >
      <div
        className={clsx(
          "navbar-area transition-all duration-300",
          sticky ? "bg-black/90" : ""
        )}
      >
        <div className="relative px-4 md:px-8">
          <div className="row items-center">
            <div className="w-full">
              <nav className="flex items-center justify-between py-[10px] navbar navbar-expand-lg">
                <a className="navbar-brand mr-5" href="index.html">
                  <img
                    src={sticky ? LogoWhiteImg : LogoImg}
                    alt="Groove Technology – We build amazing software for your business"
                    width={150}
                    height={43}
                  />
                </a>
                <button
                  className="hidden navbar-toggler focus:outline-none lg:hidden"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarOne"
                  aria-controls="navbarOne"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>
                {stickyCTA ? (
                  <div className="flex items-center gap-4">
                    <p
                      className="font-nunito hidden md:block font-semibold text-white"
                      dangerouslySetInnerHTML={{
                        __html:
                          configuration.submitEmailPanel
                            .callToActionTextWatermark,
                      }}
                    />
                    <form
                      onSubmit={handleSubmit}
                      className="relative flex bg-white rounded-full"
                    >
                      <input
                        type="text"
                        className={clsx(
                          "p-4 pr-0 text-base h-11 font-nunito bg-white border border-white rounded-full w-full",
                          error ? "text-red-500" : ""
                        )}
                        placeholder="Your email address..."
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />
                      <svg
                        className="animate-spin h-5 w-5 mr-3 flex text-black"
                        viewBox="0 0 24 24"
                      ></svg>
                      <button
                        type="submit"
                        className="h-11 min-w-[130px] w-auto relative hidden md:inline-flex p-4 gap-2 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                      >
                        {loading ? (
                          <span
                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </span>
                        ) : (
                          configuration.submitEmailPanel?.callToActionLabel ??
                          "Submit"
                        )}
                      </button>
                      <button
                        type="submit"
                        className="h-11 w-auto inline-flex md:hidden p-4 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                      >
                        {loading ? (
                          <span
                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </span>
                        ) : (
                          <PaperAirplaneIcon className="w-4 h-4 text-white -rotate-45" />
                        )}
                      </button>
                    </form>
                  </div>
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <HeroPanel
        email={email}
        loading={loading}
        isError={isError}
        error={error}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        closeModal={closeModal}
        showModal={showModal}
      />
    </section>
  );
}
