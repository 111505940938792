import "./gspinner.css";

interface GSpinnerProps {
  useMultiRiple?: boolean;
  minHeight?: number;
  maxViewport?: boolean;
  transparentBG?: boolean;
}

function GSpinner({
  useMultiRiple = false,
  minHeight = undefined,
  maxViewport = true,
  transparentBG = false,
}: GSpinnerProps) {
  const background = transparentBG ? "bg-black opacity-50" : "bg-white";
  const maxH = maxViewport ? "h-screen" : "";
  const minH = minHeight ? `min-h-[${minHeight}px]` : "";
  return (
    <div
      className={`gspinner relative w-full flex items-center justify-center ${background} ${minH} ${maxH}`}
    >
      {useMultiRiple ? (
        <div className="multi-ripple">
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="spinner" />
      )}
    </div>
  );
}

export default GSpinner;
