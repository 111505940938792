import { useContext, useState } from "react";
import { clsx } from "clsx";
import FullStackIcon from "../../assets/images/tech/icons/Full-Stack-Developers-icon.svg";
import ReactNativeIcon from "../../assets/images/tech/icons/React-Native-icon.svg";
import WebAppIcon from "../../assets/images/tech/icons/Web-App-Developers-icon.svg";
import IOTIcon from "../../assets/images/tech/icons/IOT-icon.svg";
import DevOpsIcon from "../../assets/images/tech/icons/DevOps-icon.svg";
import UIUXIcon from "../../assets/images/tech/icons/ui-ux-icon.svg";
import BAIcon from "../../assets/images/tech/icons/BA-icon.svg";
import PMIcon from "../../assets/images/tech/icons/PM-icon.svg";
import { ConfigContext } from "../../App";
import { addSubscriberAPI } from "../../utils/api";
import { useLocation } from "react-router-dom";
import Modal from "../../components/Modal";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

const datRolePanel = {
  order: 4,
  visible: true,
  headlineText: "Trusted By Our Technologies",
  callToActionInLastCell: true,
  roles: [
    {
      icon: FullStackIcon,
      title: "Full Stack Developers",
      des: "At Groove we specialise in attracting, preparing and developing full stack developers working with the Microsoft Stack/.NET, Python, Node.JS on the backend and React.JS and Angular.JS on the front end.",
    },
    {
      icon: WebAppIcon,
      title: "Web App Developers",
      des: "Our Web Application Development team designs and develops web applications to meet specific business requirements. Leveraging our hands-on experience we simplify complex workflows with niche web applications.",
    },
    {
      icon: ReactNativeIcon,
      title: "React Native - Mobile App Developers",
      des: "Our engineers help build native apps for iOS and Android devices, and we streamline development leveraging React Native to simplify and optimize a mobile application development process.",
    },
    {
      icon: IOTIcon,
      title: "IOT Developers",
      des: "Partner with our experienced IOT engineers to develop interconnected web or mobile applications, harnessing the power of smart devices and data-driven insights for more efficient, intelligent, and user-friendly experiences.",
    },
    {
      icon: DevOpsIcon,
      title: "DevOps Engineers",
      des: "Hire our DevOps engineers to scale your business quickly and get DevOps services ranging from development, automation, plugin integration to API development.",
    },
    {
      icon: UIUXIcon,
      title: "UI/UX Designers",
      des: "Choose our skilled UI/UX designer to elevate your user experiences. From intuitive user interfaces to seamless interactions, our designer delivers impactful designs that captivate users.",
    },
    {
      icon: BAIcon,
      title: "Business Analysts",
      des: "Our Business Analysts have expertise in analyzing business requirements and translating them into clear functional specifications. They collaborate closely with stakeholders to ensure that solutions meet the business objectives.",
    },
    {
      icon: PMIcon,
      title: "Project Managers",
      des: "Our Project Managers are skilled in planning, executing, and delivering projects on time and within budget.",
    },
    {
      icon: FullStackIcon,
      title:
        `Schedule a no obligation strategy consultation <a href="tel:+84 976 902 605">call with our delivery team</a>`,
      des: "Submit Email",
    },
  ],
};

export default function RolePanel() {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const configuration: any = useContext(ConfigContext);

  const search = useLocation().search;
  const campaignId = new URLSearchParams(search).get("campaignId") ?? "root";

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event: any) => {
    if (isValidEmail(event.target.value)) {
      setError(null);
    }
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      setError("Email is invalid");
      return;
    } else {
      setError(null);
    }
    setLoading(true);
    const res = await addSubscriberAPI({ email, campaignId });

    if (res === email) {
      setShowModal(true);
      setIsError(false);
    } else {
      setShowModal(true);
      setIsError(true);
    }
    setLoading(false);
    setEmail("");
  };

  const closeModal = () => {
    setShowModal(false);
    setIsError(false);
  };

  return (
    <section
      id="role"
      className="pb-18 bg-white relative after:block after:absolute after:bottom-0 after:left-0 after:w-full after:h-14 after:bg-gradient-to-b after:from-[#ffffff] after:to-[#f8f8f8]"
    >
      <div className="container">
        <div className="border border-border-color border-b-0">
          <ul className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
            {configuration.rolePanel.roles ??
              datRolePanel.roles.map((item, index) => {
                return index === datRolePanel.roles.length - 1 ? (
                  <li
                    key={`role-item-${index}`}
                    className={clsx(
                      "flex flex-col gap-4 p-4 lg:p-10 text-center border-b border-border-color items-center justify-center bg-black/80",
                      index % 3 === 0 ? "border-l-0" : "border-l"
                    )}
                  >
                    <p
                      className="font-nunito text-lg font-bold text-white"
                      dangerouslySetInnerHTML={{
                        __html:  item.title,
                      }}
                    />
                    <form
                      onSubmit={handleSubmit}
                      className="relative mt-1 flex bg-section rounded-full"
                    >
                      <input
                        type="text"
                        className={clsx(
                          "p-4 text-base h-11 font-nunito bg-section border border-section rounded-full w-full placeholder:text-gray2",
                          error ? "text-red-500" : ""
                        )}
                        placeholder="Your email address..."
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                      />
                      <button
                        type="submit"
                        className="h-11 w-[130px] hidden md:flex p-4 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                      >
                        {loading ? (
                          <span
                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                      <button
                        type="submit"
                        className="h-11 w-auto flex md:hidden p-4 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                      >
                        {loading ? (
                          <span
                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </span>
                        ) : (
                          <PaperAirplaneIcon className="w-4 h-4 text-white -rotate-45" />
                        )}
                      </button>
                    </form>
                  </li>
                ) : (
                  <li
                    key={`role-item-${index}`}
                    className={clsx(
                      "flex flex-col gap-4 p-4 lg:p-10 text-left border-b border-border-color",
                      index % 3 === 0 ? "border-l-0" : "border-l"
                    )}
                  >
                    <img src={item.icon} alt="" className="w-12" />
                    <p className="font-nunito text-lg font-bold">
                      {item.title}
                    </p>
                    <p className="font-nunito font-normal">{item.des}</p>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <Modal
        showModal={showModal}
        closeModal={closeModal}
        msg={
          isError
            ? configuration.submitEmailPanel.errorMessage
            : configuration.submitEmailPanel.successMessage
        }
        ctaCloseModal={configuration.submitEmailPanel.ctaCloseModal}
        ctaCloseModalLink={configuration.submitEmailPanel.ctaCloseModalLink}
        isError={isError}
      />
    </section>
  );
}
