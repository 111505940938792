import { useState } from "react";
import { PieChart, Pie } from "recharts";

export default function DonutChart({
  data,
  itemValue,
}: {
  data: any;
  itemValue: number;
}) {
  const [isAnimationEnd, setIsAnimationEnd] = useState<boolean>(false);

  return (
    <PieChart width={71} height={71}>
      <Pie
        data={data}
        dataKey="value"
        cx="50%"
        cy="50%"
        innerRadius={28}
        outerRadius={36}
        startAngle={-240}
        paddingAngle={(100 - itemValue) * 6}
        animationDuration={4000}
        onAnimationEnd={() => setIsAnimationEnd(true)}
        fill={isAnimationEnd ? "#E5E5E5" : "#e60078"}
        cornerRadius={40}
      />
    </PieChart>
  );
}
