import axios from "axios";

export async function addSubscriberAPI({
  email,
  campaignId = "root",
}: {
  email: string;
  campaignId: string;
}) {
  try {
    const options = {
      method: "POST",
      url: process.env.REACT_APP_FIRE_BASE_API_URL,
      params: { "api-version": "3.0" },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        EmailAddress: email,
        CustomFields: [
          {
            Key: "CampaignID",
            Value: campaignId,
          },
        ],
        ConsentToTrack: "Yes",
        Resubscribe: true,
        RestartSubscriptionBasedAutoresponders: true,
      },
    };
    const res = await axios.request(options).then((res) => res.data);
    return res;
  } catch (error: any) {
    console.log("error subscribe", error);
    return {
      error: error.toString(),
    };
  }
}
