import React from "react";

export default function YoutubeEmbed({ embedId }: { embedId: string }) {
  return (
    <div className="overflow-hidden pb-[56.25%] relative h-0 w-full">
      <iframe
        width="970"
        height="473"
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        className="left-0 top-0 h-full w-full absolute"
      />
    </div>
  );
}
