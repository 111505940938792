import React, { useContext } from "react";
import IconFlash from "../../assets/images/icon_flash@2x.png";
import Counter from "../../components/Counter";
import { ConfigContext } from "../../App";

export default function StatisticsPanel() {
  const configuration: any = useContext(ConfigContext);

  return (
    <section className="py-14">
      <div className="container">
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 lg:gap-20">
          <div className="flex gap-4 md:gap-6 flex-col text-left">
            <h2
              className="text-3xl leading-10 font-semibold uppercase"
              dangerouslySetInnerHTML={{
                __html: configuration.statisticsPanel.headlineText,
              }}
            />
            <ul className="flex items-center flex-col md:flex-row gap-10 md:gap-4 justify-between">
              {configuration.statisticsPanel.statistics.map((item, i) => (
                <li
                  className="flex justify-center items-center md:items-start gap-1 flex-col"
                  key={`statistic-${i}`}
                >
                  <span className="text-primary text-4xl flex items-start">
                    <Counter number={item.value} />
                    <span className="text-lg">{item.symbol}</span>
                  </span>
                  <p className="font-nunito text-base font-normal">
                    {item.text}
                  </p>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex my-6 md:mt-2">
            <ul className="flex items-start flex-col gap-6 md:gap-10">
              {configuration.statisticsPanel.bulletPoints.map((item, i) => (
                <li
                  className="flex justify-center items-start gap-2 md:gap-4"
                  key={`bullet-item-${i}`}
                >
                  <img src={IconFlash} alt="" className="w-4" />
                  <p className="font-nunito font-normal text-xl">{item}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
