import React, { useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide01Img from "../../assets/images/whyVN/1.png";
import Slide02Img from "../../assets/images/whyVN/2.png";
import Slide03Img from "../../assets/images/whyVN/3.png";
import Slide04Img from "../../assets/images/whyVN/4.png";
import Slide05Img from "../../assets/images/whyVN/5.png";
import Slide06Img from "../../assets/images/whyVN/6.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline";

export default function SwiperCarousel({ data }: { data: any[] }) {
  const sliderRef = useRef<any>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current?.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current?.swiper.slideNext();
  }, []);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return `<span class="relative w-2 h-2 !rounded-full !bg-black !opacity-100 ${className} !mx-3"></span>`;
    },
  };

  return (
    <div className="relative px-10 lg:px-4 xl-px-0">
      <Swiper
        ref={sliderRef}
        slidesPerView={1}
        breakpoints={{
          "640": {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          "768": {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          "1024": {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        spaceBetween={30}
        freeMode={true}
        pagination={pagination}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {data ??
          [
            Slide01Img,
            Slide02Img,
            Slide03Img,
            Slide04Img,
            Slide05Img,
            Slide06Img,
          ].map((item, i) => (
            <SwiperSlide key={`swiper-${i}`}>
              <div className="w-full">
                <div className="single_item mx-auto">
                  <div className="work_image">
                    <img src={item} alt="work" className="w-full" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      <div
        className="prev-arrow h-8 lg:h-12 w-8 lg:w-12 absolute inset-0 top-0 bottom-0 my-auto z-10 right-auto lg:-left-12 xl:-left-16 -left-1 cursor-pointer bg-gray2/50 flex items-center justify-center rounded-full hover:bg-primary"
        onClick={handlePrev}
      >
        <ArrowLongLeftIcon className="text-white lg:h-7 h-5" />
      </div>
      <div
        className="next-arrow h-8 lg:h-12 w-8 lg:w-12 absolute inset-0 left-auto my-auto z-10 -right-1 lg:-right-12 xl:-right-16 cursor-pointer bg-gray2/50 flex items-center justify-center rounded-full hover:bg-primary"
        onClick={handleNext}
      >
        <ArrowLongRightIcon className="text-white lg:h-7 h-5" />
      </div>
    </div>
  );
}
