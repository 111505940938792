import React, { useContext, useState } from "react";
import VideoImg from "../../assets/images/video-thumbnail@2x.png";
import PlayIcon from "../../assets/images/play-icon.png";
import IconShape1 from "../../assets/images/icon@2x.png";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import { ConfigContext } from "../../App";

export default function VideoPanel() {
  const [showPlay, setShowPlay] = useState<boolean>(false);
  const configuration: any = useContext(ConfigContext);

  const handlePlay = () => {
    setShowPlay(true);
  };

  return (
    <section id="video" className="py-14 bg-section">
      <div className="container">
        <div className="grid grid-cols-1 justify-center items-end gap-6 md:gap-10">
          <div className="flex flex-col gap-3 items-center">
            <p className="font-nunito font-normal text-sm uppercase text-primary">
              {configuration.videoPanel.topSubHeadline}
            </p>
            <h3
              className="text-2xl md:text-3xl uppercase md:pr-16 text-center"
              dangerouslySetInnerHTML={{
                __html: configuration.videoPanel.headlineText,
              }}
            />
            <p className="font-nunito font-normal text-gray text-center">
              {configuration.videoPanel.blurb}
            </p>
          </div>

          <div className="flex items-center justify-center relative">
            <div className="hero_shape hidden md:block -top-8 -left-10">
              <img
                src={IconShape1}
                alt="shape"
                className="text-black w-10 h-10"
              />
            </div>
            {showPlay ? (
              <YoutubeEmbed embedId={configuration.videoPanel.youtubeEmbedId} />
            ) : (
              <>
                <img
                  src={configuration.videoPanel.thumbnail ?? VideoImg}
                  alt="Youtube thumbnail"
                  width={"100%"}
                />
                <button
                  className="absolute top-0 bottom-0 left-0 right-0 m-auto w-22 h-22 hover:opacity-80 z-0"
                  onClick={handlePlay}
                >
                  <span className="animate-ping absolute inline-flex -inset-0 z-0 h-full w-full rounded-full bg-white opacity-75"></span>

                  <img src={PlayIcon} alt="Expect" className="relative z-10"/>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
