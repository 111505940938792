import { useContext } from "react";
import IconShape1 from "../../assets/images/icon@2x.png";
import GPTWImg from "../../assets/images/GPTW-certified@2x.png";
import { ConfigContext } from "../../App";
import clsx from "clsx";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import HeroMobileImg from "../../assets/images/hero-mobile@2x.png";

export default function HeroPanel({
  email,
  loading,
  error,
  handleChange,
  handleSubmit,
}: {
  email: string;
  loading: boolean;
  isError: boolean;
  showModal: boolean;
  error: string | null;
  handleChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  closeModal: () => void;
}) {
  const configuration: any = useContext(ConfigContext);

  return (
    <div
      id="home"
      className="header_hero relative h-screen lg:flex items-center"
    >
      <div className="absolute hidden md:block inset-0 left-auto md:left-0 md:mx-auto w-22">
        <img
          src={GPTWImg}
          alt="Groove Technology - Software Company in Australia - Viet Nam - Singapore"
          width={82}
        />
      </div>
      <div className="container relative">
        <div className="row">
          <div className="w-full md:w-2/3 lg:w-2/3 xl:w-2/5 relative z-0">
            <div className="header_hero_content mt-20 md:mt-150 text-left relative">
              <div className="hero_shape hidden lg:block -top-2 -left-10">
                <img
                  src={IconShape1}
                  alt="shape"
                  className="text-black w-10 h-10"
                />
              </div>
              <div className="relative py-4">
                <div className="absolute inset-0 w-full h-full block bg-white/80 z-0 blur-lg" />
                <div className="relative z-10">
                  <h2
                    className="hero_title text-3xl md:text-3xl font-extrabold text-left leading-10 my-4 uppercase"
                    dangerouslySetInnerHTML={{
                      __html: configuration.heroPanel.headlineText,
                    }}
                  />
                  <p className="text-lg font-normal text-black font-nunito mt-4">
                    {configuration.heroPanel.description}
                  </p>
                </div>
              </div>
              <div className="hero_btn my-2 bg-black/80 rounded-lg border p-5 flex flex-col gap-2">
                <p
                  className="font-nunito text-white font-semibold"
                  dangerouslySetInnerHTML={{
                    __html:
                      configuration.submitEmailPanel.callToActionTextWatermark,
                  }}
                />
                <form
                  onSubmit={handleSubmit}
                  className={clsx("relative mt-1 flex bg-white rounded-full")}
                >
                  <input
                    type="text"
                    className={clsx(
                      "p-4 text-base h-11 font-nunito bg-white border border-white rounded-full w-full",
                      error ? "text-red-500" : ""
                    )}
                    placeholder="Your email address..."
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  <button
                    type="submit"
                    className="h-11 w-[130px] hidden md:flex p-4 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                  >
                    {loading ? (
                      <span
                        className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </span>
                    ) : (
                      configuration.submitEmailPanel.callToActionLabel ??
                      "Submit"
                    )}
                  </button>
                  <button
                    type="submit"
                    className="h-11 w-auto flex md:hidden p-4 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                  >
                    {loading ? (
                      <span
                        className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </span>
                    ) : (
                      <PaperAirplaneIcon className="w-4 h-4 text-white -rotate-45" />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <img
          src={HeroMobileImg}
          alt="Groove TechnologyGroove Technology - Software Company in Australia - Viet Nam - Singapore - 8"
        />
      </div>
    </div>
  );
}
