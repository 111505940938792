import clsx from "clsx";
import GSpinner from "../MyGSpinner/GSpinner";

interface Props {
  transparentBG?: boolean;
  isFullScreen?: boolean;
}

function Loading({
  transparentBG = false,
  isFullScreen = true,
}: Props) {
  return (
    <div
      className={clsx(
        isFullScreen
          ? "h-screen w-screen bg-white flex justify-center items-center"
          : "table-loading flex h-full",
      )}>
      <GSpinner
        transparentBG={transparentBG}
        maxViewport={false}
        minHeight={300}
        useMultiRiple={true}
      />
    </div>
  );
}

export default Loading;
