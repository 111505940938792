import React, { useContext } from "react";
import ExpectImg from "../../assets/images/expect-img@2x.png";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import ScrollAnimation from "react-animate-on-scroll";
import { ConfigContext } from "../../App";

export default function WorkingWithPanel() {
  const configuration: any = useContext(ConfigContext);

  return (
    <section id="working-with" className="pt-14 pb-8">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 items-center">
          <div className="flex flex-col gap-6 lg:gap-10 text-left">
            <ScrollAnimation animateIn="fadeIn">
              <h3
                className="text-2xl md:text-3xl uppercase md:pr-16"
                dangerouslySetInnerHTML={{
                  __html: configuration.workingWithPanel.headlineText,
                }}
              />
              <ul className="flex flex-col gap-4 md:gap-6 pt-6 md:pt-12">
                {configuration.workingWithPanel.bulletPoints.map((item, i) => (
                  <li
                    className="flex items-center gap-2 relative pl-8"
                    key={`working-item-${i}`}
                  >
                    <CheckCircleIcon className="w-5 h-5 block text-green-400 absolute inset-0 top-[2px]" />
                    <p className="font-nunito text-base font-normal">{item}</p>
                  </li>
                ))}
              </ul>
            </ScrollAnimation>
          </div>

          <div className="flex items-center justify-center mt-8 md:mt-0">
            <ScrollAnimation animateIn="fadeIn">
              <img
                src={configuration.workingWithPanel.rightImage ?? ExpectImg}
                alt="Working with Groove Technology"
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
}
