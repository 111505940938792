import CountUp from "react-countup";

export default function Counter({
  number,
  title,
}: {
  number: number;
  title?: string;
}) {
  return <CountUp start={0} end={number} delay={0} />;
}
