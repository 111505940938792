import { createContext, useEffect, useState } from "react";
import "./App.css";
import "animate.css/animate.min.css";
import Page from "./sections/page";
import { config as configDefault } from "./configuration";
import { useLocation } from "react-router-dom";
import { merge } from "lodash";
import Loading from "./components/Loading";
import { db } from "./utils/firebase";
import { onValue, ref } from "firebase/database";

export const ConfigContext = createContext(configDefault[0]);

function App() {
  const [config, setConfig] = useState<any>(undefined);
  const [currentConfig, setCurrentConfig] = useState<any>(undefined);
  const search = useLocation().search;
  const campaignId = new URLSearchParams(search).get("campaignId") ?? "root";

  useEffect(() => {
    const query = ref(db);

    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        setConfig(Object.values(data));
      }
    });
  }, []);

  useEffect(() => {
    if (config) {
      const configurationByCampaignId = config.find(
        (item: any) => item.configurationId === campaignId
      );
      const configurationRoot = config.find(
        (item: any) => item.configurationId === "root"
      );
      const configMapped = merge(configurationRoot, configurationByCampaignId);
      setCurrentConfig(configMapped);
    }
  }, [campaignId, config]);

  if (!currentConfig) return <Loading />;

  return (
    <ConfigContext.Provider value={currentConfig?.configuration as any}>
      <Page />
    </ConfigContext.Provider>
  );
}

export default App;
