import React, { useContext, useState } from "react";
import EmployeeImg from "../../assets/images/employee-img@2x.png";
import Counter from "../../components/Counter";
import DonutChart from "../../components/DonutChart";
import ScrollAnimation from "react-animate-on-scroll";
import { ConfigContext } from "../../App";

export default function GreatPlacePanel() {
  const [startChartRender, setStartChartRender] = useState(false);
  const configuration: any = useContext(ConfigContext);

  return (
    <section id="great-place" className="py-14">
      <div className="container">
        <h3
          className="text-2xl md:text-3xl uppercase md:pr-16 pb-6 md:pb-14 text-center"
          dangerouslySetInnerHTML={{
            __html: configuration.greatPlacePanel.headlineText,
          }}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-16">
          <ScrollAnimation
            animateIn="fadeIn"
            afterAnimatedIn={() => setStartChartRender(true) as any}
          >
            <div className="flex flex-col gap-6 md:gap-10 text-center md:text-left md:mt-16">
              <ul className="flex flex-col gap-4 md:gap-14">
                {configuration.greatPlacePanel.statistics
                  .slice(
                    0,
                    configuration.greatPlacePanel.statistics?.length / 2 ?? 3
                  )
                  .map((item, i) => (
                    <li
                      key={`great-place-${i}`}
                      className="flex flex-col items-center md:items-end text-center md:text-right gap-4"
                    >
                      <span className="text-black text-2xl flex items-center justify-center border-[6px] border-transparent w-18 h-18 rounded-full relative">
                        {startChartRender ? (
                          <>
                            <Counter number={item.value} />
                            <div className="absolute -inset-1 -top-[6px] -left-[6px]">
                              <DonutChart
                                data={[{ name: "Test", value: item.value }]}
                                itemValue={item.value}
                              />
                            </div>
                            <span className="text-base">{item.symbol}</span>
                          </>
                        ) : null}
                      </span>

                      <p className="font-nunito text-base font-normal">
                        {item.des}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn">
            <div className="flex items-center justify-center py-4">
              <img
                src={
                  configuration.greatPlacePanel.centerImageUrl ?? EmployeeImg
                }
                alt="Greate Place To Work"
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeIn"
            afterAnimatedIn={() => setStartChartRender(true) as any}
          >
            <div className="flex flex-col gap-6 md:gap-10 text-center md:text-left md:mt-16">
              <ul className="flex flex-col gap-4 md:gap-14">
                {configuration.greatPlacePanel.statistics
                  .slice(
                    configuration.greatPlacePanel.statistics?.length / 2 ?? 3,
                    configuration.greatPlacePanel.statistics?.length
                  )
                  .map((item, i) => (
                    <li
                      key={`great-place-right-${i}`}
                      className="flex flex-col items-center md:items-start text-center md:text-left gap-4"
                    >
                      <span className="text-black text-2xl flex items-center justify-center border-[6px] border-transparent w-18 h-18 rounded-full relative">
                        {startChartRender ? (
                          <>
                            <Counter number={item.value} />
                            <div className="absolute -inset-1 -top-[6px] -left-[6px]">
                              <DonutChart
                                data={[{ name: "Test", value: item.value }]}
                                itemValue={item.value}
                              />
                            </div>
                            <span className="text-base">{item.symbol}</span>
                          </>
                        ) : null}
                      </span>
                      <p className="font-nunito text-base font-normal">
                        {item.des}
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
}
