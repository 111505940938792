import {
  ArrowUturnLeftIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

export default function Modal({
  showModal,
  closeModal,
  msg,
  isError = false,
  ctaCloseModal = "Back to Home page",
  ctaCloseModalLink = "/",
}: {
  showModal: boolean;
  closeModal: () => void;
  msg: string;
  isError?: boolean;
  ctaCloseModal?: string;
  ctaCloseModalLink?: string;
}) {
  return showModal ? (
    <div
      className="z-50 h-screen w-screen flex fixed overflow-hidden underline justify-center items-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        onClick={closeModal}
        className="fixed inset-0 z-10 bg-black bg-opacity-75 transition-opacity h-screen w-screen"
      ></div>

      <div className="fixed inset-0 z-20 overflow-y-auto max-w-lg h-[300px] max-h-56 m-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative z-40 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex gap-2 justify-start items-center">
                {isError ? (
                  <ExclamationCircleIcon className="w-14 text-red-500" />
                ) : (
                  <CheckCircleIcon className="w-24 text-green-500" />
                )}
                <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <div className="">
                    <p
                      className="text-base font-nunito font-normal text-left"
                      dangerouslySetInnerHTML={{
                        __html: msg,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-8 pb-4 flex justify-center">
                {isError ? (
                  <button
                    onClick={closeModal}
                    type="button"
                    className="h-8 min-w-[130px] w-auto inline-flex gap-2 p-4 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                  >
                    <ArrowUturnLeftIcon className="w-4 h-4" /> Close
                  </button>
                ) : (
                  <a
                    onClick={closeModal}
                    href={ctaCloseModalLink}
                    target="_blank"
                    rel="noreferrer"
                    type="button"
                    className="h-8 min-w-[130px] w-auto inline-flex gap-2 p-4 font-nunito bg-primary text-white border border-primary rounded-full items-center justify-center disabled:cursor-not-allowed hover:bg-pink-600"
                  >
                    {ctaCloseModal}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
