import SwiperCarousel from "../../components/SwiperCarousel";
import { useContext } from "react";
import { ConfigContext } from "../../App";

export default function VietnamPanel() {
  const configuration: any = useContext(ConfigContext);

  return (
    <section id="vietnam" className="py-14 bg-section">
      <div className="container">
        <h3
          className="text-2xl md:text-3xl uppercase md:pr-16 pb-6 md:pb-12 text-center"
          dangerouslySetInnerHTML={{
            __html: configuration?.vietnamPanel?.headlineText,
          }}
        />
        <SwiperCarousel data={configuration?.vietnamPanel?.images}/>
      </div>
    </section>
  );
}
