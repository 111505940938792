// import DotNetImg from "../../assets/images/tech/net.png";
// import PythonImg from "../../assets/images/tech/python.png";
// import NodeJSImg from "../../assets/images/tech/node.js.png";
// import ReactImg from "../../assets/images/tech/react-web.png";
// import AngularImg from "../../assets/images/tech/angular.png";
// import ReactNativeImg from "../../assets/images/tech/react-native.png";
// import AndroidImg from "../../assets/images/tech/android.png";
// import IOSImg from "../../assets/images/tech/ios.png";
// import AWSImg from "../../assets/images/tech/amazone.png";
// import AzureImg from "../../assets/images/tech/azure.png";
// import FullStackIcon from "../../assets/images/tech/icons/Full-Stack-Developers-icon.png";
// import ReactNativeIcon from "../../assets/images/tech/icons/React-Native-icon.png";
// import WebAppIcon from "../../assets/images/tech/icons/Web-App-Developers-icon.png";
// import IOTIcon from "../../assets/images/tech/icons/IOT-icon.png";
// import DevOpsIcon from "../../assets/images/tech/icons/DevOps-icon.png";
// import UIUXIcon from "../../assets/images/tech/icons/ui-ux-icon.png";
// import BAIcon from "../../assets/images/tech/icons/BA-icon.png";
// import PMIcon from "%PUBLIC_URL%/images/tech/icons/PM-icon.png";

export const config = [
  {
    configurationId: "root",
    configuration: {
      submitEmailPanel: {
        visible: true,
        callToActionTextWatermark:
          "Schedule a no obligation strategy consultation call with our delivery team",
        callToActionLabel: "Submit",
        callToActionButton: "/",
      },
      heroPanel: {
        order: 1,
        visible: true,
        heroImage: null,
        callToActionLabel: "Schedule Default",
        headlineText: `<span class="text-primary">Amazing</span> Software
        Professionals Unleash 
        <span class="text-primary">Limitless</span> Potential For
        Your Business`,
        description:
          "Partner with Vietnam’s premier software outsource/outstaff provider and confidently sidestep the challenges of hiring locally",
      },
      statisticsPanel: {
        order: 2,
        visible: true,
        headlineText:
          "“WE ARE A PEOPLE ORIENTED ORGANISATION, WE DO OUTSOURCING THE RIGHT WAY AND WE’RE VERY GOOD AT IT”",
        statistics: [
          {
            value: 250,
            text: "Employees",
            symbol: "+",
          },
          {
            value: 94,
            text: "Customer Retention",
            symbol: "%",
          },
          {
            value: 96,
            text: "Employee Satisfaction",
            symbol: "%",
          },
        ],
        bulletPoints: [
          "Top shelf talent working right by your side",
          "Stress free continuity for your business",
          "Best practices in everything we do",
          "Transparent operations, transparent fees",
        ],
      },
      technologiesPanel: {
        order: 3,
        visible: true,
        headlineText: "Trusted By Our Technologies",
        technologyImages: null, //TODO use schema below with icon URL
        // [
        //   // DotNetImg,
        //   // PythonImg,
        //   // NodeJSImg,
        //   // ReactImg,
        //   // AngularImg,
        //   // AndroidImg,
        //   // ReactNativeImg,
        //   // IOSImg,
        //   // AWSImg,
        //   // AzureImg,
        // ],
      },
      rolePanel: {
        order: 4,
        visible: true,
        headlineText: "Trusted By Our Technologies",
        roles: null, //TODO use schema below with icon URL
        // [
        //   {
        //     icon: "", //FullStackIcon,
        //     title: "Full Stack Developers",
        //     des: "At Groove we specialise in attracting, preparing and developing full stack developers working with the Microsoft Stack/.NET, Python, Node.JS on the backend and React.JS and Angular.JS on the front end.",
        //   },
        //   {
        //     icon: "", //WebAppIcon,
        //     title: "Web App Developers",
        //     des: "Our Web Application Development team designs and develops web applications to meet specific business requirements. Leveraging our hands-on experience we simplify complex workflows with niche web applications.",
        //   },
        //   {
        //     icon: "", //ReactNativeIcon,
        //     title: "React Native - Mobile App Developers",
        //     des: "Our engineers help build native apps for iOS and Android devices, and we streamline development leveraging React Native to simplify and optimize a mobile application development process.",
        //   },
        //   {
        //     icon: "", //IOTIcon,
        //     title: "IOT Developers",
        //     des: "Partner with our experienced IOT engineers to develop interconnected web or mobile applications, harnessing the power of smart devices and data-driven insights for more efficient, intelligent, and user-friendly experiences.",
        //   },
        //   {
        //     icon: "", //DevOpsIcon,
        //     title: "DevOps Engineers",
        //     des: "Hire our DevOps engineers to scale your business quickly and get DevOps services ranging from development, automation, plugin integration to API development.",
        //   },
        //   {
        //     icon: "", //UIUXIcon,
        //     title: "UI/UX Designers",
        //     des: "Choose our skilled UI/UX designer to elevate your user experiences. From intuitive user interfaces to seamless interactions, our designer delivers impactful designs that captivate users.",
        //   },
        //   {
        //     icon: "", //BAIcon,
        //     title: "Business Analysts",
        //     des: "Our Business Analysts have expertise in analyzing business requirements and translating them into clear functional specifications. They collaborate closely with stakeholders to ensure that solutions meet the business objectives.",
        //   },
        //   {
        //     icon: "", //PMIcon,
        //     title: "Project Managers",
        //     des: "Our Project Managers are skilled in planning, executing, and delivering projects on time and within budget.",
        //   },
        //   {
        //     icon: "", //FullStackIcon,
        //     title:
        //       "Schedule a no obligation strategy consultation call with our delivery team",
        //     des: "Submit Email",
        //   },
        // ],
      },
      workingWithPanel: {
        order: 5,
        visible: true,
        headlineText: `“WHAT CAN I <span class="text-primary">EXPECT</span> FROM GROOVE TECHNOLOGY?”`,
        rightImage: null,
        bulletPoints: [
          "Compliment your existing team with remote software professionals",
          "Tech Leads, Senior and Mid level Engineers, BA’s, PM’s & UI/UX",
          "Full documentation for everything we learn and build",
          "Adapt to your existing process or adopt ours for maximum efficiency",
          "Full traceability for every line of code written and decision made",
          "Security as a first principle",
          "Collaborative performance management",
          "Career development planning for sustainable growth",
        ],
      },
      videoPanel: {
        order: 6,
        visible: true,
        topSubHeadline: "LIFE AT GROOVE",
        headlineText: `TOGETHER WE BUILD OUR <span class="text-primary">AMAZING</span> WORKPLACE`,
        blurb:
          "At Groove, we work hard while also have fun together and enjoy our sweet success.",
        youtubeEmbedId: "t4SAMXBRHeM",
        thumbnail: "",
      },
      greatPlacePanel: {
        order: 7,
        visible: true,
        headlineText: `OUR EMPLOYEES HAVE <span class="text-primary">SPOKEN</span>!`,
        centerImageUrl: "",
        statistics: [
          {
            value: 96,
            symbol: "%",
            des: "96% of employees at Groove Technology say it is a great place to work compared to 53% of employees at a typical Global company.",
          },
          {
            value: 99,
            symbol: "%",
            des: "I am given the resources and equipment to do my job",
          },
          {
            value: 98,
            symbol: "%",
            des: "I am treated as a full member regardless of my position",
          },
          {
            value: 95,
            symbol: "%",
            des: "People are encouraged to balance their work life and their personal life",
          },
          {
            value: 95,
            symbol: "%",
            des: "Management genuinely seeks and responds to suggestions and ideas",
          },
          {
            value: 99,
            symbol: "%",
            des: "When you join the company, you are made to feel welcome",
          },
        ],
      },
      vietnamPanel: {
        order: 8,
        visible: true,
        headlineText:
          "Why <span class='text-primary'>Vietnam</span> FOR YOUR OUTSOURCING?",
        images: null,
      },
    },
  },
  {
    configurationId: "202306GoogleAdWords",
    configuration: {
      heroPanel: {
        headlineText: `<span class="text-primary">Great TUna</span> Software
        Professionals Unleash 
        <span class="text-primary">Limitless</span> Potential For
        Your Business`,
        description:
          "Partner with Vietnam’s premier software outsource/outstaff provider and confidently sidestep the challenges of hiring locally",
      },
      employeeVideoPanel: {
        visible: "false",
      },
    },
  },
  {
    configurationId: "202307Facebook009",
    configuration: {
      employeeVideoPanel: {
        visible: "false",
      },
    },
  },
];

export const configAPIUrl =
  "https://64aebc81c85640541d4d9ed4.mockapi.io/configuration";
