import React from "react";
import VietNamFlag from "../../assets/images/footer/vn-flag@2x.png";
import AUFlag from "../../assets/images/footer/AU-flag@2x.png";
import HKFlag from "../../assets/images/footer/HK-flag@2x.png";
import ISO9001 from "../../assets/images/footer/groove-technology-icon-footer-iso-9001.svg";
import ISO27001 from "../../assets/images/footer/groove-technology-icon-footer-iso-27001.svg";
import Microsoft from "../../assets/images/footer/groove-technology-icon-footer-microsoft-GPN.svg";
import GPTW from "../../assets/images/footer/groove-technology-icon-footer-GPTW.svg";
import FacebookIcon from "../../assets/images/footer/fb-normal@2x.png";
import LinkedIcon from "../../assets/images/footer/linked-in-normal@2x.png";
import FacebookActiveIcon from "../../assets/images/footer/fb-active@2x.png";
import LinkedActiveIcon from "../../assets/images/footer/linked-in-active@2x.png";

export default function Footer() {
  return (
    <footer id="footer" className="footer_area bg-footer relative z-10">
      <div className="border-b border-[#2f495a] hidden">
        <div className="container">
          <div className="flex flex-col md:flex-row justify-center items-center md:justify-between pb-4 md:pb-0">
            <ul className="flex items-center gap-4 md:gap-10">
              <li className="font-nunito text-white hover:text-primary">
                <a href="/" className="block py-4 text-primary">
                  Home
                </a>
              </li>
              <li className="font-nunito text-white hover:text-primary">
                <a
                  href="https://groovetechnology.com/software-development-services/#professional_services"
                  target="_blank"
                  rel="noreferrer"
                  className="block py-4"
                >
                  Service
                </a>
              </li>
              <li className="font-nunito text-white hover:text-primary">
                <a
                  href="https://career.groovetechnology.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="block py-4"
                >
                  Careers
                </a>
              </li>
              <li className="font-nunito text-white hover:text-primary">
                <a
                  href="https://groovetechnology.com/contact-us/"
                  target="_blank"
                  rel="noreferrer"
                  className="block py-4"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <ul className="flex gap-2 items-center">
              <li className="">
                <a
                  href="https://www.facebook.com/groovetechnologycom/"
                  target="_blank"
                  rel="noreferrer"
                  className="link-img"
                >
                  <img
                    src={FacebookIcon}
                    alt="GrooveTechnology Facebook"
                    className="w-12"
                  />
                  <img
                    src={FacebookActiveIcon}
                    alt="GrooveTechnology Facebook"
                    className="w-12 link-img-active"
                  />
                </a>
              </li>
              <li className="">
                <a
                  href="https://www.linkedin.com/company/groove-technology"
                  target="_blank"
                  rel="noreferrer"
                  className="link-img"
                >
                  <img
                    src={LinkedIcon}
                    alt="GrooveTechnology Linked"
                    className="w-12"
                  />
                  <img
                    src={LinkedActiveIcon}
                    alt="GrooveTechnology Linked"
                    className="w-12 link-img-active"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container py-8 md:py-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-18">
          <div className="flex items-start text-left gap-4 md:gap-6 text-white">
            <img src={VietNamFlag} alt="VietNam" className="w-10 h-10" />
            <div className="flex flex-col items-start gap-4">
              <h3 className="text-2xl text-primary uppercase">VietNam</h3>
              <address className="font-nunito font-normal text-white not-italic">
                Floor 18, Havana Tower, 132 Ham Nghi, Ben Thanh Ward, District
                1, Ho Chi Minh City, Vietnam.
              </address>
            </div>
          </div>
          <div className="flex items-start text-left gap-4 md:gap-6 text-white">
            <img src={AUFlag} alt="Australia" className="w-10 h-10" />
            <div className="flex flex-col items-start gap-4">
              <h3 className="text-2xl text-primary uppercase">AUSTRALIA</h3>
              <address className="font-nunito font-normal text-white not-italic">
                Suite 115, 358 Clarendon Street, South Melbourne, Victoria,
                Australia, 3205.
              </address>
            </div>
          </div>
          <div className="flex items-start text-left gap-4 md:gap-6 text-white">
            <img src={HKFlag} alt="Hong Kong" className="w-10 h-10" />
            <div className="flex flex-col items-start gap-4">
              <h3 className="text-2xl text-primary uppercase">HONG KONG</h3>
              <address className="font-nunito font-normal text-white not-italic">
                5/F, Yat Chau Building, 262 Des Voeux Road Central, Hong Kong.
              </address>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 pt-8 md:pt-14 items-center">
          <ul className="flex justify-center md:justify-start gap-2">
            <li>
              <img src={ISO9001} alt="ISO 9001" />
            </li>
            <li>
              <img src={ISO27001} alt="ISO 27001" />
            </li>
            <li>
              <img src={Microsoft} alt="Microsoft" />
            </li>
            <li>
              <img src={GPTW} alt="Greate place" />
            </li>
          </ul>
          <div className="">
            <p className="font-nunito font-normal text-white pt-6 md:pt-0 md:text-right text-center">
              Copyright ©2022 Groove Technology. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
