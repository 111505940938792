import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAc7xo74y7z8TlM89aZy3jUqS7IuGrqyxw",
  authDomain: "partnerwith-8fc50.firebaseapp.com",
  databaseURL: "https://partnerwith-8fc50-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "partnerwith-8fc50",
  storageBucket: "partnerwith-8fc50.appspot.com",
  messagingSenderId: "134163455198",
  appId: "1:134163455198:web:bb09544a58b615c3a8764f",
  measurementId: "G-X0CCRRNKTR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service
export const db = getDatabase(app);